import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactAudioPlayer from 'react-audio-player';

import Playlist from './Playlist';

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
`;

/* eslint-disable */

const TrackInfo = styled.div`
  padding: 10px 0;
  display: flex;
  text-transform: capitalize;
  .track-sep {
    &:after {
      margin: 0 0.5em;
      content: '-';
    }
  }
`;

const Title = styled.div`
  font-family: 'HelveticaNeue-Light', 'Open Sans', sans serif;
  font-weight: 700;
  font-size: 18px;
`;

class AudioPlayer extends React.Component {
  static propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        filename: PropTypes.string,
      }),
    ),
    stopAudioPlayers: PropTypes.func.isRequired,
    // playerDetails: PropTypes.func,
    onIsPlaying: PropTypes.func,
    id: PropTypes.string.isRequired,
    showAlbum: PropTypes.bool,
  }

  static defaultProps = {
    list: [
      {
        title: '',
        album: '',
      },
    ],
    onIsPlaying: null,
    showAlbum: true,
  }

  state = {
    track: {
      title: '',
      album: '',
    },
  }

  constructor(props) {
    super(props);
    this.playlistIndex = 0;
  }

  componentWillMount() {
    const { list } = this.props;
    if (list.length > 0) {
      this.setState({
        trackItem: list[0],
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { list } = nextProps;
    if (list.length > 0) {
      this.setState({ track: list[0] });
    }
  }

  onSelectTrack = ({ trackItem }) => {
    const { list } = this.props;
    this.playlistIndex = list.findIndex(item => item.title === trackItem.title);
    this.setState(
      { trackItem },
      () => {
        const { stopAudioPlayers, id } = this.props;
        stopAudioPlayers({ id });
        this.player.audioEl.play();
      },
    );
  }

  stop = () => {
    try {
      this.player.audioEl.pause();
    } catch (e) {
      console.warn('couldnt stop player', e);
    }
  }

  playNextTrack = () => {
    const { list } = this.props;
    if (this.playlistIndex < list.length - 1) {
      this.playlistIndex += 1;
      this.onSelectTrack({ trackItem: list[this.playlistIndex] });
    }
  }

  render() {
    const { trackItem } = this.state;
    const { list, stopAudioPlayers, id } = this.props;
    return (
      <Container>
        <ReactAudioPlayer
          style={{ width: '100%' }}
          src={trackItem.track.file.url}
          controls
          controlsList="nodownload"
          ref={(c) => { this.player = c; }}
          onEnded={this.playNextTrack}
          onPlay={() => stopAudioPlayers({ id })}
        />
        {
          list && (
            <Playlist
              list={list}
              onClick={this.onSelectTrack}
              currentTrack={trackItem}
            />
          )
        }
      </Container>
    );
  }
}

export default AudioPlayer;
