import styled from 'styled-components';

const Title = styled.h1`
  font-size: 1.4rem;
  text-transform: capitalize;
  margin-top: 4px;
  margin-bottom: 0px;
`;

export default Title;
