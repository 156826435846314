import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0;
  line-height: 24px;
  font-size: 16px;
  font-family: 'HelveticaNeue-Light', 'Open Sans', sans serif;

  &:hover {
    cursor: pointer;
    color: var(--active-color);
    font-weight: 400;
  }

  font-weight: ${props => (props.isActive ? 700 : 400)};
`;

const TrackDetails = (props) => {
  const {
    activeTrack,
    id,
    title,
    duration,
  } = props;
  const isActive = id === activeTrack.id;
  return (
    <Container isActive={isActive}>
      <div>{title}</div>
      <div>{duration}</div>
    </Container>
  );
};


TrackDetails.propTypes = {
  duration: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  activeTrack: PropTypes.shape({
    duration: PropTypes.string,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

TrackDetails.defaultProps = {
  duration: 'n/a',
};

export default TrackDetails;
