import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PlaylistItem from './PlaylistItem';
import TrackDetails from './TrackDetails';

const Container = styled.div`
  line-height: 30px;
  padding: 0 10px;
`;

const Playlist = (props) => {
  const { list, onClick, currentTrack } = props;
  return (
    <Container>
      {
        list.map(trackItem => (
          <PlaylistItem
            key={trackItem.title}
            type="button"
            onClick={() => onClick({ trackItem })}
          >
            <TrackDetails
              id={trackItem.id}
              duration={trackItem.duration}
              title={trackItem.title}
              activeTrack={currentTrack}
            />
          </PlaylistItem>
        ))
      }
    </Container>
  );
};


Playlist.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      filename: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  currentTrack: PropTypes.shape({}).isRequired,
};

export default Playlist;
