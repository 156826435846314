import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Block from '../components/block/Block';
import Article from '../components/article/Article';
import Container from '../components/block/Container';

const audioPlayers = {};

const registerAudioPlayer = ({ id, playerRef }) => {
  audioPlayers[id] = playerRef;
};

const stopAudioPlayers = ({ id }) => {
  Object.keys(audioPlayers).forEach((key) => {
    if (key !== id) {
      audioPlayers[key].stop();
    }
  });
};

const BlogPost = ({ data, intl: { locale, formatMessage } }) => {
  const {
    title, // body, image, tags,
  } = data[locale];
  return (
    <Layout>
      <SEO title={title} />
      <Container>
        <Block
          title={formatMessage({ id: 'menu@activities' })}
          style={{ maxWidth: 720 }}
        >
          <Article
            {...data[locale]}
            registerAudioPlayer={registerAudioPlayer}
            stopAudioPlayers={stopAudioPlayers}
          />
        </Block>
      </Container>
      {/* <div className="blogpost">
        <h1>{title}</h1>
        {
          image && (
            <img alt={title} src={image.image.file.url} />
          )
        }
        <div className="tags">
          {
            tags && (
              tags.map(tag => (
                <span className="tag" key={tag}>
                  {tag}
                </span>
              ))
            )
          }
        </div>
        <p className="body-text">{body.body}</p>
        <Link to="/blogposts">View more posts</Link>
        <Link to="/">Back to Home</Link>
      </div> */}
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    contentfulBlogPost: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      image: PropTypes.shape({
        image: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  intl: PropTypes.shape({
    locale: PropTypes.string,
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(BlogPost);

// export const pageQuery = graphql`
//   query($slug: String!) {
//     contentfulBlogPost(slug: { eq: $slug }) {
//       title
//       slug
//       body {
//         body
//       }
//       playlist {
//         title
//         track {
//           file {
//             fileName
//             url
//           }
//         }
//         duration
//       }
//       image {
//         image {
//           id
//           file {
//             url
//             fileName
//           }
//         }
//         portrait
//       }
//       tags
//     }
//   }
// `;

export const pageQuery = graphql`
  query IndexSlugQuery($slug: String!) {
    en: contentfulBlogPost(slug: {eq: $slug}, node_locale: {eq: "en-US"}) {
      id
      slug
      title
      date
      body {
        json
      }
      playlist {
        id
        title
        track {
          file {
            fileName
            url
          }
        }
        duration
      }
      image {
        image {
          id
          file {
            url
            fileName
          }
        }
        portrait
      }
    },
    fr: contentfulBlogPost(slug: {eq: $slug}, node_locale: {eq: "fr"}) {
      id
      slug
      title
      date
      body {
        json
      }
      playlist {
        id
        title
        track {
          file {
            fileName
            url
          }
        }
        duration
      }
      image {
        image {
          id
          file {
            url
            fileName
          }
        }
        portrait
      }
    }
  }
`;
