import React, { useState } from 'react';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import PropTypes from 'prop-types';

const MediaContainer = styled.div`
  height: 360px;
  text-align: left;
  margin-bottom: 10px;
  display: flex;

  /* Small only */
  @media screen and (max-width: 39.9375em) {
    height: 200px;
  }

  /* Medium and up */
  @media screen and (min-width: 40em) {}

  /* Medium only */
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {}

  /* Large and up */
  @media screen and (min-width: 64em) {}

  /* Large only */
  @media screen and (min-width: 64em) and (max-width: 74.9375em) {}
`;

const ImageTag = styled.img`
  &:hover {
    cursor: pointer;
  }
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0px;
`;

const Image = (props) => {
  const { url, title } = props;
  const [zoom, setZoom] = useState(false);

  return (
    <MediaContainer onClick={() => setZoom(true)}>
      <ImageTag
        src={`${url}`}
        alt={title}
      />
      {
        zoom && (
          <Lightbox
            mainSrc={`${url}`}
            onCloseRequest={() => setZoom(false)}
          />
        )
      }
    </MediaContainer>
  );
};

Image.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Image;
