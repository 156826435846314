import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import localization from 'moment/locale/fr';

import Title from './Title';
import ArticleDate from './ArticleDate';
import Image from './Image';
import RichBody from './RichBody';
import AudioPlayer from '../audio/AudioPlayer';

const Container = styled.div`
`;

const SeparatorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 0;
`;

// const Separator = styled.div`
//   height: 10px;
//   width: 10px;
//   background-color: #21a9ed;
//   border-radius: 50%;
// `;

const Content = styled.div`
  white-space: pre-line;
  text-align: justify;
`;

const ArticleHeader = styled.div`
  margin-bottom: 20px;
`;

class Article extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    image: PropTypes.shape({
      image: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      portrait: PropTypes.bool,
    }),
    playlist: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        track: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    ),
    body: PropTypes.shape({
      json: PropTypes.shape({}),
    }).isRequired,
    registerAudioPlayer: PropTypes.func.isRequired,
    stopAudioPlayers: PropTypes.func.isRequired,

    intl: PropTypes.shape({
      locale: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    playlist: null,
    image: null,
  }

  constructor(props) {
    super(props);
    const { intl: { locale } } = this.props;
    moment.locale(locale, localization);
  }

  getImageOrientation = isPortrait => (
    isPortrait ? 'portrait' : 'landscape'
  )

  render() {
    const {
      id, title, date, image, body, playlist,
      registerAudioPlayer, stopAudioPlayers,
    } = this.props;

    return (
      <Container>
        <ArticleHeader>
          <Title>{title}</Title>
          <ArticleDate>{moment(date).format('LL')}</ArticleDate>
        </ArticleHeader>
        {
          image && (
            <Image
              url={image.image.file.url}
              title={title}
            />
          )
        }
        {
          body && (
            <Content>
              <RichBody json={body.json} />
            </Content>
          )
        }
        {
          playlist && (
            <AudioPlayer
              ref={(c) => {
                this.player = c;
                registerAudioPlayer({
                  id,
                  playerRef: this.player,
                });
              }}
              list={playlist}
              id={id}
              stopAudioPlayers={stopAudioPlayers}
            />
          )
        }
        <SeparatorContainer />
        {/* <SeparatorContainer>
          <Separator />
        </SeparatorContainer> */}
      </Container>
    );
  }
}

export default injectIntl(Article);
