import React from 'react';
import PropTypes from 'prop-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const RichBody = ({ json }) => {
  const raw = documentToHtmlString(json);
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: raw }} />;
};
RichBody.propTypes = {
  json: PropTypes.shape({}).isRequired,
};

export default RichBody;
